import { BrowserRouter, Route } from "react-router-dom";
import Index from "./pages/Index";
import './App.css';

const App=()=>
{
  return <BrowserRouter>
    <Route path="/" exact component={Index}/>
  </BrowserRouter>
}

export default App;
