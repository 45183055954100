import React from 'react';

const Appbar = () =>
{
    return <div className="appbar-container">
    <a  target="_blank"  href="https://baumlanus.com.ar/">
        <img alt="Imagen de Baum Lanús" src="https://i.imgur.com/O0JFzFr.jpeg" className={"image-appbar"} />
    </a>
    </div>
}

export default Appbar
