import React from 'react';
import styled from "styled-components";

const Container=styled.div`
display:block;
padding:25px;
`;

const Espacio=styled.div`
margin:10px;`;

const Titulos=styled.div`
font-weight:bold;
font-size: 21px;

@media (max-width: 800px) 
{
    font-size: 15px;
}
`;

const MensajeInfo = () => 
{
    return <Container>
            <div>
            <p>Con la progresiva vuelta a la normalidad 😃 y por razones contractuales vigentes, <strong>Pedidos Ya</strong> es el encargado exclusivo de llevar Baum a sus casas por las noches! 🏡🌜</p>
            <Espacio/>
            <p>Pero como no nos olvidamos de que nos bancaron tanto, queremos ofrecerles un 10% de descuento en takeaway! tan solo pidiendo por whatsapp como siempre 💪🏼</p>
            <Espacio/>
            <p>Les dejamos los links y horarios actuales:</p>
            <Espacio/>
            <Titulos>-Pedidos Ya</Titulos>
            <a target="_blank" rel="noreferrer" href="https://pedidosya.com.ar/restaurantes/lanus/baum-lanus-menu">pedidosya.com.ar/restaurantes/lanus/baum-lanus-menu</a>
            <p>Todos los días de 12 a 23 hs.</p>
            <Espacio/>
            <Titulos>-Takeaway</Titulos>
            <a target="_blank" rel="noreferrer" href="https://encasa.baumlanus.com.ar">encasa.baumlanus.com.ar</a>
            <p>Todos los días de 12 a 23 hs.</p>
            <Espacio/>
            <Titulos>-Delivery propio</Titulos>
            <a target="_blank" rel="noreferrer" href="https://encasa.baumlanus.com.ar">encasa.baumlanus.com.ar</a>
            <p>Lunes a Sábados de 12 a 16 hs.</p>
            <Espacio/>
            <Titulos>-Bar</Titulos>
            <a target="_blank" rel="noreferrer" href="https://baumlanus.com.ar">baumlanus.com.ar</a>
            <p>Viernes, sábados y vísperas de 12:00 a 3:00 hs.</p>
            <p>Jueves, Domingos y feriados de 13:00 a 2:00 hs.</p>
            <p>Lunes, Martes y Miercoles de 12:00 a 1:00 hs.</p>
            </div>
        </Container>
}

export default MensajeInfo
