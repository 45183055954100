import React from "react";
import Appbar from '../components/Appbar';
import MensajeInfo from "../components/MensajeInfo";
import '../styles/index.css'
import '../styles/appbar.css'
import '../styles/carrito.css'
import '../styles/categoria.css'
import '../styles/HomeDesktop.css'
import '../styles/Homepage.css'
import '../styles/index.css'
import '../styles/producto.css'
import '../styles/productoCarrito.css'
import '../styles/WhatsappButton.css'


const Index=()=>
{
    return <div>
        <Appbar/>
        <MensajeInfo/>
    </div>
   
}

export default Index;